import React from 'react'

const Banner = (props) => (
    <section id="banner" className="major">
        <div className="inner">
            <header className="banner" style={{textAlign: "center"}}>
                <h1>Angela M. Tuscher</h1>
            </header>
        </div>
    </section>
)

export default Banner
