import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import Banner from '../components/Banner'

import {Link} from 'gatsby'

import angie_jazz from '../assets/images/angie_jazz.png'
import gg from '../assets/images/gg_color.jpg'

class HomeIndex extends React.Component {
    render() {

        return (
            <Layout>
                <Helmet
                    title="Angela M. Tuscher"
                    meta={[
                        { name: '', content: 'Westernreittrainerin und Gebärdensprachdolmetscherin' },
                        { name: 'keywords', content: 'Gebärdensprachdolmetschen' },
                        { name: 'keywords', content: 'Westernreiten' },
                    ]}
                >
                </Helmet>

                <Banner />

                <div id="main">
            <section id="two" className="spotlights">
                <section>
                    <div className="image">
                        <img src={angie_jazz} alt="" />
                    </div>
                    <div className="content">
                        <div className="inner">
                            <header className="major">
                                <h3>Westernreiten</h3>
                            </header>
                            <p>feinfühlig</p>
                            <p>individuell</p>
                            <p>nachhaltig</p>
                        </div>
                    </div>
                </section>
                <section>
                    <div to="/" className="image">
                        <img src={gg} alt="" />
                    </div>
                    <div className="content">
                        <div className="inner">
                            <header className="major">
                                <h3>Gebärdensprachdolmetschen</h3>
                            </header>
                            <p>verlässlich</p>
                            <p>authentisch</p>
                            <p>professionell</p>
                        </div>
                    </div>
                </section>
            </section>
        </div>

            </Layout>
        )
    }
}

export default HomeIndex